import { SVGProps } from "react";
export default function IconCheckboxIndeterminate(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM14 10H4V8H14V10Z" />
    </svg>
  );
}
